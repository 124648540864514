import React, { Suspense, lazy } from "react";
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useParams,
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Spinner from "../layout-elements/Spinner";

// Lazy load components
const PaySellerInfo = lazy(() => import("./public/PaySellerInfo"));
const InspectItemsInfo = lazy(() => import("./public/InspectItemsInfo"));
const Contact = lazy(() => import("./public/Contact"));
const ReportIssue = lazy(() => import("./public/ReportIssue"));
const PageNotFound = lazy(() => import("./public/PageNotFound"));
const HomePage = lazy(() => import("./public/HomePage"));
const PrivacyPolicy = lazy(() => import("./public/PrivacyPolicy"));
const TermsOfService = lazy(() => import("./public/TermsOfService"));
const LiabilityWaiver = lazy(() => import("./public/LiabilityWaiver"));
const PackingTips = lazy(() => import("./public/PackingTips"));
const ServiceArea = lazy(() => import("./public/ServiceArea"));
const Review = lazy(() => import("./Review"));
const Schedule = lazy(() => import("./Schedule"));
const Trip = lazy(() => import("./Trip"));
const SellerPortal = lazy(() => import("./SellerPortal"));
const CheckoutForm = lazy(() => import("./CheckoutForm"));
const PaymentConfirmation = lazy(() => import("./PaymentConfirmation"));
const TripList = lazy(() => import("./TripsList"));
const ApplyPromoCode = lazy(() => import("./public/ApplyPromoCode"));
const EmailListSignUp = lazy(() =>
  import("./public/home-page-elements/EmailListSignUp")
);
const EmailReceipt = lazy(() => import("./public/EmailReceipt"));
const PriceOverview = lazy(() =>
  import("./public/home-page-elements/PriceOverview")
);
const HowItWorks = lazy(() => import("./public/HowItWorks"));
const Reviews = lazy(() => import("./../pages/public/ReviewPage"));

const LoginRedirect = () => {
  const { phoneInstance } = useParams();
  return (
    <Navigate
      to={`/auth?questions=%5B"PhoneInput"%2C"OtpVerify"%5D&phoneInstance=${
        phoneInstance ? phoneInstance : "login"
      }`}
      replace
    />
  );
};

const PageNotFoundRedirect = () => {
  return <Navigate to={`/page-not-found`} replace />;
};

const InviteRedirect = () => {
  const { tripID } = useParams();
  return (
    <Navigate to={`/seller-portal?tripID=${tripID}&seller=true`} replace />
  );
};

const ApprovalRedirect = () => {
  const { tripID } = useParams();
  return (
    <Navigate
      to={`/schedule?tripID=${tripID}&stage=5&questions=%5B"PhoneInput"%2C"OtpVerify"%5D&phoneInstance=approveTrip`}
      replace
    />
  );
};

const PromoCodeRedirect = () => {
  const { promoCode } = useParams();
  return <Navigate to={`/promo-code?promoCode=${promoCode}`} replace />;
};

const PaymentRedirect = () => {
  const { tripID } = useParams();
  return <Navigate to={`/trip?tripID=${tripID}`} replace />;
};

const TripRedirect = () => {
  const { tripID } = useParams();
  return <Navigate to={`/trip?tripID=${tripID}`} replace />;
};

// New Redirect component for handling /kwpickup.com in the path
const InvalidSubdomainRedirect = () => {
  const location = useLocation();
  const newPath = location.pathname.replace("/kwpickup.com", ""); // Remove /kwpickup.com from the path

  return <Navigate to={newPath} replace />;
};

const AnimatedRoutes = ({ setProgressBar }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams();
  const estimateQuestions = [
    "MultiItems",
    "SelItemDesc",
    "ImageUpload",
    "ItemDesc",
    "PickupVerification",
    "PickupAccessibility",
    "Disassemble",
    "DropoffVerification",
    "DropoffAccessibility",
    "Reassemble",
    "ConfirmTime",
    "AtHome",
    "SelectDateTime",
    "SellerPayment",
    "FinalPrice",
    "InspectionRequired",
    "HowInspection",
    "WhatInspections",
  ];
  searchParams.set("questions", JSON.stringify(estimateQuestions));
  const newEstimatePath = `/schedule?${searchParams.toString()}&stage=1`;

  return (
    <AnimatePresence>
      <Suspense
        fallback={
          <div className="flex items-center justify-center h-full">
            <Spinner />
          </div>
        }
      >
        <Routes location={location} key={location.pathname}>
          {/* PUBLIC ROUTES */}
          <Route path="/" element={<HomePage />} />
          <Route path="/pay-seller" element={<PaySellerInfo />} />
          <Route path="/item-inspection" element={<InspectItemsInfo />} />
          <Route path="/report-issue" element={<ReportIssue />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/liability-waiver" element={<LiabilityWaiver />} />
          <Route path="/packing-tips" element={<PackingTips />} />
          <Route path="/service-area" element={<ServiceArea />} />
          {/* APPLICATION ROUTES */}
          <Route path="/login" element={<LoginRedirect />} />
          <Route path="/auth" element={<Schedule />} />
          <Route path="/new-account" element={<Schedule />} />
          <Route path="/estimate" element={<Navigate to={newEstimatePath} />} />
          <Route path="/review" element={<Review />} />
          <Route
            path="/schedule"
            element={<Schedule setProgressBar={setProgressBar} />}
          />
          <Route path="/invite/:tripID" element={<InviteRedirect />} />
          <Route path="/seller-portal" element={<SellerPortal />} />
          <Route path="/trip" element={<Trip />} />
          <Route path="/trip/:tripID" element={<TripRedirect />} />
          <Route path="/trips" element={<TripList />} />
          <Route path="/pay/:tripID" element={<PaymentRedirect />} />
          <Route path="/checkout" element={<CheckoutForm />} />
          <Route
            path="/payment-confirmation"
            element={<PaymentConfirmation />}
          />
          <Route path="/promo/:promoCode" element={<PromoCodeRedirect />} />
          <Route path="/promo-code" element={<ApplyPromoCode />} />
          <Route path="/email-list" element={<EmailListSignUp />} />
          <Route path="/email-receipt" element={<EmailReceipt />} />
          <Route path="/pricing" element={<PriceOverview />} />
          <Route path="/service-overview" element={<HowItWorks />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/approve/:tripID" element={<ApprovalRedirect />} />

          {/* NEW route for /kwpickup.com/ */}
          <Route
            path="/kwpickup.com/*"
            element={<InvalidSubdomainRedirect />}
          />

          {/* Catch-all route for undefined paths */}
          <Route path="*" element={<PageNotFoundRedirect />} />
          <Route path="/page-not-found" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
